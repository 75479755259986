
import './App.css';
import {Routes, Route} from 'react-router-dom';
import CopyTrade from './Copytrade2';
import RegisterUid from './Register';
import Masterlogin from './Masterlogin11';
//import DataContext from './DataContext';

function App() {
  return (
    <Routes>
     
    
    <Route path='/'   element={<RegisterUid/>}></Route>
    <Route path='/masterlogin' element={ <Masterlogin/> }  ></Route>
    <Route path='/' element={ <CopyTrade/> }  ></Route>

   
  </Routes>
  )
}

export default App;
