import { useState, useEffect } from "react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import CopyTrade from "./Copytrade2";
import { useUser } from "./context/userContext";
import './App.css';

function Masterlogin() {
  const [api_key, setApi_key] = useState("");
  const [secret_key, setSecret_key] = useState("");
  const [hidereg, setHidereg] = useState(0);
  const {
    user,
    setUser,
    masterbroker,
    setMasterbroker,
    Checkmaster,
    setCheckmaster,
    brokerdata,
    setBrokerdata,
    brokerName,
    setBrokerName,
    setChecklogin,
  } = useUser();
  const navigate = useNavigate();

  const baseURL = "https://multitrade-api.quantpower.tech" ;



//   console.log("setuser", user);
//   console.log("localuser",JSON.parse(localStorage.getItem("localuser")))
//   const Usernamelocaldata =JSON.parse(localStorage.getItem(
//     "Username",
  
//   ));
//   const uuidlocaldata =JSON.parse(localStorage.getItem(
//     "Useruuid",
  
//   ));
//   console.log('first', Usernamelocaldata,uuidlocaldata)
  //const userlocaldata = JSON.parse(localStorage.getItem("localuser"))
 // console.log('3243',userlocaldata);
  //setUser(userlocaldata)

  // useEffect(() => {
  //   if (!user.uuid) {
  //       setUser(JSON.parse(localStorage.getItem("localuser")))
  //   }
  // }, []);
//  console.log('7889',user);
  useEffect(() => {
    if (user.uuid) {
      getBrokers();
    }
  }, [user]);


  const sendData = () => {
    const brokeritem = brokerdata.find((i) => i.broker_name === brokerName);
    console.log('send datta',brokeritem,"JKBKJB",brokerName);
    if (brokeritem) {
      setMasterbroker({
        brokeritem: brokeritem,
        api_key: api_key,
        secret_key: secret_key,
      });
    }
    console.log("ebtr", masterbroker);
  };

  useEffect(() => {
      console.log("useeffect mastertbroker",masterbroker);
    if (Object.keys(masterbroker).length > 0) {
      MasterLogin();
    }
  }, [masterbroker]);

 

  const getBrokers = () => {
    axios
      .get(`${baseURL}/copytrade/allbroker`)
      .then((response) => {
        if (response.data.length > 0) {
          setBrokerdata(response.data);
          setBrokerName(response.data[0].broker_name);

          // const Redirectlink = (`https://multitrade-api.quantpower.tech/${(response.data[0].broker_name).toLocaleLowerCase()}_view/${user.uuid}/${response.data[0].broker_id}/`)
          // window.open(Redirectlink, "_blank")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  const MasterLogin = () => {
    console.log("workingcsc", user,masterbroker);
    axios
      .post(
        `${baseURL}/copytrade/broker_satup/${masterbroker.brokeritem.broker_id}/master/${user.uuid}`,
        {
          api_key: masterbroker.api_key,
          api_secret: masterbroker.secret_key,
        }
      )
      .then((response) => {
        if (response.data) {
          //save in localstrage everytime
         
          localStorage.setItem("localmasterbroker",JSON.stringify(masterbroker))
          window.open(response.data, "_blank");
          //   console.log("localmasterbroker", JSON.parse(localStorage.getItem("localmasterbroker") ));
         
          // setHidereg(2);
          // toast.success('Login successfully')
         // gettabledata();
      
        

        
          navigate("/copytrade");

          
        } else {
          //toast.error('Credentials are Invalid')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

 
  return (
    <>
      <div>
        <div
          className=" d-flex align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="container ">
            <div className="col-12 col-md-5 mx-auto ">
              <div className=" progress h-8">
                <div
                  className="progress-bar progress-bar-striped bg-primary w-100 false"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-5 mx-auto py-4 px-3 rounded shadow-lg">
                <h4 className="">Master Broker!</h4>
                <span className="text-muted fs-14">Add Your Master Broker</span>

                <div className="pt-2">
                  <label className="form-label text-muted fw-semibold fs-16">
                    Select Broker Name
                  </label>
                  <select
                    className="form-select"
                    value={brokerName}
                    onChange={(e) => {
                      setBrokerName(e.target.value);
                    }}
                  >
                    {brokerdata &&
                      brokerdata?.map((item) => {
                        return (
                          <option key={item.broker_id} value={item.broker_name}>
                            {item.broker_name}
                          </option>
                        );
                      })}
                  </select>

                  <label className="form-label text-muted fw-semibold mt-2 fs-16">
                    Api Key
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={api_key}
                    onChange={(e) => setApi_key(e.target.value)}
                    placeholder="Enter valid Api Key"
                  />
                  <label className="form-label text-muted fw-semibold mt-2 fs-16">
                    Secret Key
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={secret_key}
                    onChange={(e) => setSecret_key(e.target.value)}
                    placeholder="Enter valid Secret Key"
                  />
                </div>
                <div className="">
                  <button
                    className="btn btn-primary w-100 py-2 mt-3"
                    type="submit"
                    onClick={() => sendData()}
                    to="/copytrade"
                    disabled={api_key && secret_key ? false : true}
                  >
                    Login
                  </button>
                  <div className="mt-3">
                    <span className="mt-3 fw-semibold text-muted me-2">
                      Create New User
                    </span>
                    <span>
                      <Link
                        className="text-decoration-none"
                        // onClick={() => setHidereg(0)}
                        to="/"
                      >
                        SignIn?
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {hidereg === 2 && <CopyTrade />}
    </>
  );
}

export default Masterlogin;
