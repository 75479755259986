import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();
const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  
  const [masterbroker, setMasterbroker] = useState({});
  const [Checkmaster, setCheckmaster] = useState("");
  const [brokerdata, setBrokerdata] = useState([]);
  const [brokerName, setBrokerName] = useState("");
  const [checklogin, setChecklogin] = useState("");

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        masterbroker,
        setMasterbroker,
        Checkmaster,
        setCheckmaster,
        brokerdata,
        setBrokerdata,
        brokerName,
        setBrokerName,
        checklogin,
        setChecklogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
export default UserProvider;
