// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fs-8{
  font-size: 8px;
}
.fs-10{
  font-size: 10px;
}
.fs-12{
  font-size: 12px;
}
.fs-14{
  font-size: 14px;
}
.fs-16{
  font-size: 16px;
}
.h-8{
  height: 8px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;AACb","sourcesContent":[".fs-8{\n  font-size: 8px;\n}\n.fs-10{\n  font-size: 10px;\n}\n.fs-12{\n  font-size: 12px;\n}\n.fs-14{\n  font-size: 14px;\n}\n.fs-16{\n  font-size: 16px;\n}\n.h-8{\n  height: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
