import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./App.css";

import { useUser } from "./context/userContext";



function RegisterUid() {
//   const [hidereg, setHidereg] = useState(0);
  const [username, setUsername] = useState("");
  const {setUser } = useUser();

  const baseURL = "https://multitrade-api.quantpower.tech";
  //  console.log(brokerName);

  const navigate = useNavigate();
  
  const formSubmit = (e) => {
    e.preventDefault();
   

    axios
      .post(`${baseURL}/copytrade/copyreg`, {
        user_name: username,
      })
      .then((response) => {
        if (response) {
          const dataobj = {"user_name": username,"uuid":Object.values(response?.data)?.[0]}
          setUser(dataobj);
        //  console.log('page1',dataobj)
          localStorage.setItem(
                "localuser", JSON.stringify(dataobj),
              );
            //   console.log("localuser",JSON.parse(localStorage.getItem("localuser")))

          // toast.success("Registration successfully");
          // getBrokers();
       

          navigate("/masterlogin");
          // setHidereg(1);
        } else {
          // toast.error("User Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* {hidereg === 0 && ( */}
        <div
          className=" d-flex align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="container ">
            <div className="col-12 col-md-5 mx-auto ">
              <div className=" progress h-8">
                <div
                  className="progress-bar progress-bar-striped w-100 bg-primary false"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-5 py-4 px-3 rounded shadow-lg">
                <form onSubmit={formSubmit}>
                  <h4 className="m-0"> Welcome To Multi Trade!</h4>
                  <span className="text-muted  fs-14">
                    Register Yourself Here{" "}
                  </span>

                  <div className="pb-3 pt-2">
                    <label className="form-label fw-semibold fs-14 ">
                      User Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Username"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="">
                    <button
                      className="btn btn-primary w-100 py-2 "
                      // onClick={() => setShow(true)}

                      type="submit"
                    >
                      Get Started
                    </button>
                    <div className="mt-3">
                      <span className="mt-3 fw-semibold text-muted me-2">
                        Already Register
                      </span>
                      <span>
                        <Link
                          className="text-decoration-none"
                          to="/masterlogin"
                        //   onClick={() => setHidereg(1)}
                        >
                          SignUp?
                        </Link>{" "}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
}

export default RegisterUid;
