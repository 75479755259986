import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import {
  FaPaste,
  FaRegTrashCan,
  FaUserPlus,
  FaArrowsRotate,
  FaArrowRightToBracket,
  FaPlus,
} from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./context/userContext";
import "./App.css";
//import Masterlogin from "./Masterlogin";

function CopyTrade() {
  const {
    user,
    setUser,
    Checkmaster,
    setCheckmaster,
    masterbroker,
    setMasterbroker,
    brokerdata,
    setBrokerdata,
    brokerName,

    checklogin,
    setChecklogin,
  } = useUser();

  const [getFund, setGetFund] = useState({});
  const [masterbrokerfetch, setMasterbrokerfetch] = useState("");
  const [Modalmasterbroker, setModalMasterbroker] = useState("");
  const [loginitem, setLoginitem] = useState({});
  const [api_key, setApi_key] = useState("");
  const [secret_key, setSecret_key] = useState("");
  const [table_data, setTable_data] = useState([]);
  const [tradestart, setTradeStart] = useState("");
  const [tradenow, setTradenow] = useState(false);
  const [order, setOrder] = useState(false);
  const [selectedOption, setSelectedOption] = useState("MIS");
  const [selectedValue, setSelectedValue] = useState("MARKET");
  const [daylocselect, setdaylocselect] = useState("DAY");
  const [formdata, setFormdata] = useState({
    qty: 1,
    price: 100,
  });
  const [searchTerm, setSearchTerm] = useState("NIFTY 50");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedsymbolValue, setSelectedsymbolValue] = useState({});





  const baseURL = "https://multitrade-api.quantpower.tech";

  const navigate = useNavigate();

  console.log("orignal user", user);
  console.log("localuser", JSON.parse(localStorage.getItem("localuser")));
  console.log("localmasterbroker", JSON.parse(localStorage.getItem("localmasterbroker")));

  useEffect(() => {
    const local_userData = JSON.parse(localStorage.getItem("localuser"));
    if (!user.uuid) {
      console.log("workimg im user lovcal ", local_userData);
      setUser(local_userData);
    }

    const local_masterbroker = JSON.parse(
      localStorage.getItem("localmasterbroker")
    );
    if (!masterbroker.brokeritem) {
      setMasterbroker(local_masterbroker);
    }

    getBrokers();
  }, []);

  useEffect(() => {
    console.log("updated user effect", masterbroker, "user", user);
    if (user.uuid) {
      getcheckmaster();
      gettabledata();
    }
  }, [user]);

  // useEffect(() => {
  //   console.log("updated in effect", masterbroker, "user", user);
  //   if (masterbroker.api_key && user.uuid) {
  //     console.log("updated masterbroker effect", masterbroker, "user", user);
  //     if (loginitem.logged_in) {
  //       getFunds();
  //     }
  //   }
  // }, [masterbroker]);
  useEffect(() => {
      //  console.log(loginitem);
      if (loginitem.logged_in) {
        getFunds();
        //local
        // setTable_data(updateddata)
      }
  }, [loginitem]);

  const getBrokers = () => {
    axios
      .get(`${baseURL}/copytrade/allbroker`)
      .then((response) => {
        if (response.data.length > 0) {
          setBrokerdata(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getcheckmaster = () => {
    // if(user.uuid) {
    axios
      .get(`${baseURL}/copytrade/checkmaster/${user.uuid}/`)
      .then((response) => {
        setCheckmaster(response.data);
      });
    // }
  };

  const gettabledata = () => {
    // if (user.uuid) {
      axios
        .get(` ${baseURL}/copytrade/checklogin/${user.uuid}/`)
        .then((response) => {
          // console.log(
          //   "checklogin resuklt",
          //   response,
          //   response.data[0].master_child,
          //   response.data[0].logged_in
          // );
          setChecklogin(response.data);
          const item = response.data.find((i) => i.master_child === "master");
         // console.log(item);
          setLoginitem(item);
        });
    // }
  };

  const getFunds = () => {
    console.log("getfunds ", masterbroker);

    axios
      .get(
        `${baseURL}/copytrade/masterfund/${masterbroker.brokeritem.broker_id}/${masterbroker.api_key}/${user.uuid}`
      )
      .then((response) => {
        console.log("getfunds 0", response);
        setGetFund(response.data.balance);
        setMasterbrokerfetch(masterbroker.brokeritem.broker_name)
        setModalMasterbroker(masterbroker.brokeritem.broker_name)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onrefresh =() => {
    gettabledata()
    getFunds()
  }

  const copystring = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
  };

  const AddChildBroker = () => {
    const data_is = {
      child_broker: Modalmasterbroker,
      child_apikey: api_key,
      child_secretkey: secret_key,
      lots: 1,
    };
    
    setTable_data((prevtabledata) => [...prevtabledata, data_is]);
  
  //  localStorage.setItem("localtabledata",JSON.stringify())
   
   
  };

 // console.log("localtabledata", JSON.parse(localStorage.getItem("localtabledata")));
  const handleRemoveClick = (index) => {
    const item = checklogin.find((i) => i.api_key === masterbroker.api_key);
    //  console.log('res', item, )
    if (item) {
      axios.get(
        `${baseURL}/copytrade/CTdel/${user.uuid}/${masterbroker.api_key}/${item.id}/`
      );

      //  console.log('res', item, checklogin)
      gettabledata();

      const list = [...table_data];
      list.splice(index, 1);
      setTable_data(list);
    } else {
      console.log("item is not found");
    }
  };

  // const linkSTr = `https://quantpower.tech/${masterbrokerfetch}/JilinIVsEoStR7UIWkKI/`;



  const handlelogin = (child_broker, child_apikey, child_secretkey, lots) => {
    //   console.log('gh', child_broker, child_apikey, child_secretkey, lots ,uuidlocaldata.uuid)
      const brokeritem = brokerdata.find((i) => i.broker_name === child_broker);
      axios
        .post(
          `${baseURL}/copytrade/broker_satup/${brokeritem.broker_id}/child/${user.uuid}`,
          {
            api_key: child_apikey,
            api_secret: child_secretkey,
            qty: lots,
          }
        )
        .then((response) => {
          console.log("child",response)
          // if (!response.data.msg) {
          window.open(response.data, "_blank");
          // localStorage.setItem("localtabledata",JSON.stringify(table_data))
          gettabledata(); //later remove
          // handleRemoveClick()
          // } else {
          //     // handleRemoveClick(index)
          //     alert('user already exist , try different api and secret key')
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  //  console.log("localtabledata", JSON.parse(localStorage.getItem("localtabledata")));
    
    // const updateddata =JSON.parse(localStorage.getItem("localtabledata"))

  const checkedvalidation = (e, index) => {
    const updatedTableData = [...table_data];
    const { value } = e.target;
    let checklot = parseInt(value);
    //  console.log('res2', checklot, isNaN(checklot), !checklot)

    if (checklot === 0) {
      // console.log('first')
      checklot = 1;
    }
    // console.log('res', checklot, isNaN(checklot))
    updatedTableData[index].lots = checklot;
    setTable_data(updatedTableData);
  };

  const checkonBlur = (e, index) => {
    const updatedTableData = [...table_data];
    const { value } = e.target;
    let checklot = parseInt(value);
    if (!checklot) {
      checklot = 1;
      updatedTableData[index].lots = checklot;
      setTable_data(updatedTableData);
    }
  };

  const checktable = () => {
    gettabledata();
  };

  const [createlink, setCreatelink] = useState("")
  const handlemodelselect = (e) => {
    setModalMasterbroker(e.target.value)
    
    
    
  };

  useEffect(() => {
    if(Modalmasterbroker){
      const item = (brokerdata.find((i) => i.broker_name === Modalmasterbroker))
      setCreatelink(
      `https://multitrade-api.quantpower.tech/${(Modalmasterbroker).toLocaleLowerCase()}_view/${user.uuid}/${item.broker_id}/${loginitem.id}`
    )}
  }, [Modalmasterbroker])
  


  
  const Searchvalue = async (value) => {
    console.log("api ", value, searchResults);

    await axios
      .get(`${baseURL}/copytrade/getSymbols?search=${value}`)

      .then(({ data }) => {
        // setSearchResults(response.data.symbol_data);
        setSearchResults(
          data.symbol_data?.map((item) => ({
            label: item.tradingsymbol,
            value: item.tradingsymbol,
            items: item,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const handleInputChange = (value) => {
    console.log(value);
    setSearchTerm(value);
  };

  const handleSelectChange = (value) => {
    console.log("Selected value: ", value);
    // setSelectedsymbolValue(value);
    if (value.length > 2) {
      Searchvalue(value);
    }
  };


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // console.log("MISNRML:", event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    //  console.log("Selected market:", event.target.value);
  };

  const handledaylocselect = (event) => {
    setdaylocselect(event.target.value);
    // console.log("setdaylocselect:", event.target.value);
  };
  const Finaldata = () => {
    axios
      .post(`${baseURL}/copytrade/trade/${user.uuid}/`, {
        exchange: selectedsymbolValue.exchange || "",
        symbol: selectedsymbolValue.tradingsymbol || "",
        price: formdata.price.toString(),
        od_trns: !order ? "BUY" : "SELL",
        od_mis: selectedOption,
        od_type: selectedValue,
        qty: (selectedsymbolValue.lot_size || 1) * formdata.qty.toString(),
        od_validity: daylocselect,
      })
      .then((response) => {
        console.log(response);

        alert("Placed Ordered");
       // navigate("/copytrade");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const savedata = () => {
    axios
      .post(`${baseURL}/copytrade/savedata/${user.uuid}`, {
        exchange: selectedsymbolValue.exchange || "",
        symbol: selectedsymbolValue.tradingsymbol || "",
        price: formdata.price.toString(),
        od_trns: !order ? "BUY" : "SELL",
        od_mis: selectedOption,
        od_type: selectedValue,
        qty: (selectedsymbolValue.lot_size || 1) * formdata.qty.toString(),
        od_validity: daylocselect,
      })
      .then((response) => {
        console.log(response);

       // navigate("/copytrade");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //console.log('loginnnn',loginitem);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="card">
          <div className="card-header bg-primary-subtle py-3">
            <span className="fw-bold me-2">COPY TRADE</span>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-3 col-12">
                <label className="form-label text-muted fs-14 fw-semibold">
                  MASTER ACCOUNT
                </label>
                <input
                  className="form-control  "
                  readOnly
                  type="text"
                  value={masterbrokerfetch}
                />
              </div>
              <div className="col-md-3 col-6 ">
                <label className="form-label text-muted fs-14 fw-semibold ms-2 ">
                  FUNDS
                </label>
                <input
                  className="form-control  "
                  readOnly
                  type="text"
                  value={getFund.total_margin ? getFund.total_margin : " ---- "}
                />
              </div>

              <div className="col-md-3 col-6">
                <label className="form-label text-muted fs-14 fw-semibold">
                  QUANTITY
                </label>
                <input
                  className="form-control "
                  readOnly
                  type="text"
                  value={
                    getFund.available_value ? getFund.available_value : " ---- "
                  }
                />
              </div>

              <div className="mt-3 d-flex col-md-3 align-self-end justify-content-between">
                {Checkmaster && (
                  <button
                    className="col-6 btn  btn-primary me-1"
                    onClick={() => onrefresh()}
                  >
                    <FaArrowsRotate /> Refresh
                  </button>
                )}

                <button
                  className=" col-6 btn btn-success"
                  href="/masterlogin"
                  //   onClick={() => getback()}
                >
                  Change Master
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loginitem.logged_in && (
        <div className="card mt-3 rounded">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 my-2 ">
                <div className="d-flex justify-content-between pb-3">
                  <h6>CHILD ACCOUNT</h6>

                  <div>
                    <button
                      className="btn btn-primary me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#stockModal"
                    >
                      <FaUserPlus />
                    </button>
                    <button
                      className="btn btn-primary "
                      onClick={() => checktable()}
                    >
                      checktable
                    </button>
                  </div>
                </div>

                <div
                  className="table-responsive rounded"
                  style={{ maxHeight: "40vh" }}
                >
                  <table className="table text-center align-middle border table-hover fs-12">
                    <thead
                      className="table-primary position-sticky bg-body z-1"
                      style={{ top: "-1px" }}
                    >
                      <tr>
                        <th>BROKER</th>
                        <th>API KEY</th>
                        <th>SECRET KEY</th>
                        <th>LOTS</th>
                        <th>STATUS</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {table_data &&
                        table_data?.map((item, index) => {
                          const tablelogged = checklogin.find(
                            (i) => i.api_key === item.child_apikey
                          );
                          //  tablelogged.logged_in

                          return (
                            <tr key={index}>
                              <td>{item.child_broker}</td>
                              <td>{item.child_apikey}</td>
                              <td>{item.child_secretkey}</td>
                              <td className="text-center">
                                <input
                                  type="number"
                                  className="form-control form-control-sm w-25 mx-auto"
                                  value={item.lots}
                                  min={1}
                                  step={1}
                                  onInput={(e) => checkedvalidation(e, index)}
                                  onBlur={(e) => checkonBlur(e, index)}
                                />
                              </td>
                              <td>
                            
                              {tablelogged ? (tablelogged.logged_in ? "Connected" : "ADDED" ): "pending"}
                                
                              </td>
                              <td>
                                <div className="d-flex justify-content-evenly">
                                  <button
                                    className="btn btn-success btn-sm"
                                    onClick={() =>
                                      handlelogin(
                                        item.child_broker,
                                        item.child_apikey,
                                        item.child_secretkey,
                                        item.lots
                                      )
                                    }
                                  >
                                    <FaArrowRightToBracket />
                                  </button>

                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleRemoveClick(index)}
                                  >
                                    <FaRegTrashCan />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {/* {checklogin &&
                                                        checklogin?.map((item, index) => (
                                                            
                                                            
                                                            <tr key={index}>
                                                                <td>{(brokerdata.find((i) => i.broker_id === item.broker)).broker_name}</td>
                                                                <td>{item.api_key}</td>
                                                                <td>{item.api_secret}</td>
                                                                <td className='text-center'>
                                                                    <input type='number' className='form-control form-control-sm w-25 mx-auto' value={11} min={1} step={1} onInput={(e) => checkedvalidation(e, index)} onBlur={(e) => checkonBlur(e, index)} />
                                                                </td>
                                                                <td>{item.logged_in ? "connected" : "Added"}</td>
                                                                <td>
                                                                    <div className="d-flex justify-content-evenly">
                                                                        <button
                                                                            className="btn btn-success btn-sm" onClick={() => handlelogin((brokerdata.find((i) => i.broker_id === item.broker)).broker_name, item.api_key, item.api_secret, 11)} >
                                                                            <FaArrowRightToBracket />
                                                                        </button>
                                                                       

                                                                        <button className="btn btn-danger btn-sm"
                                                                            onClick={() => handleRemoveClick(index)}
                                                                        >
                                                                            <FaRegTrashCan />
                                                                        </button>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <hr />
          </div>
        </div>
      )}

      <div className="modal" tabIndex="-1" id="stockModal">
        <div className="modal-dialog modal-lg modal-sm">
          <div className="modal-content">
            <div className="modal-header bg-primary-subtle">
              <div className="fw-semibold nmodal-title">
                {" "}
                Setup Child Broker
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="">
                  <div>
                    <select
                      className="form-select"
                      value={Modalmasterbroker}
                      onChange={(e) => handlemodelselect(e)}
                    >
                      {brokerdata &&
                        brokerdata?.map((item) => {
                          return (
                            <option
                              key={item.broker_id}
                              value={item.broker_name}
                            >
                              {item.broker_name}
                            </option>
                          );
                        })}
                      {/* {Object.values(masterbroker) &&
                        Object.values(masterbroker) ?.map((item) => {
                          return (
                            <option
                              key={item.broker_id}
                              value={item.broker_name}
                            >
                              {item.broker_name}
                            </option>
                          );
                        })} */}
                    </select>
                  </div>
                  <div className="row mt-3 ms-3">
                    <ul className="fs-6" style={{ listStyle: "numerical" }}>
                      <li className="mb-1">
                        Please to go to:{" "}
                        <Link className="text-primary" target="_blank">
                          account.{(Modalmasterbroker).toLocaleLowerCase()}
                          .com/developer/apps
                        </Link>{" "}
                        and login to your account.
                      </li>
                      <li>
                        Click on{" "}
                        <span className="rounded btn btn-sm btn-primary  ">
                          <FaPlus className="me-2 mb-1" />
                          New App
                        </span>
                        <ul style={{ listStyle: "numerical" }}>
                          <li>Enter any App Name.</li>
                          <li>
                            Copy and paste this url as your Redirect URL: <br />
                            <span className="text-bg-secondary m-1 px-1 rounded ">
                              {createlink}
                              {/* {linkSTr} */}
                            </span>
                            <button
                              className="rounded btn btn-sm btn-primary tx-semibold tx-11 ms-2 hover:bg-secondary "
                              onClick={() => copystring(createlink)}
                            >
                              <FaPaste className="mb-1" />
                            </button>
                          </li>
                        </ul>
                      </li>
                      <li>
                        Click on App Details. Copy your API Key and Secret, and
                        paste below.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <div className="row">
                <div className=" col-6 col-md-6">
                  <label className="text-muted fs-14">API Key</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={api_key}
                    onChange={(e) => setApi_key(e.target.value)}
                  />
                </div>
                <div className="col-6 col-md-6">
                  <label className="text-muted fs-14">Secret Key</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={secret_key}
                    onChange={(e) => setSecret_key(e.target.value)}
                  />
                </div>
              </div>
              <button
                type="button"
                className="align-self-end btn btn-primary "
                onClick={() => AddChildBroker()}
                data-bs-dismiss="modal"
                aria-label="Close"
                disabled={api_key && secret_key ? false : true}
              >
                Add {Modalmasterbroker}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className={`modal-header bg-${
                !order ? "primary" : "danger"
              }-subtle py-3`}
            >
              <div className="d-flex justify-content-start align-items-center">
                <span className="fw-bold">{!order ? "BUY" : "SELL"}</span>
                <span className="ms-2 fs-12">
                  {selectedsymbolValue && selectedsymbolValue.exchange}
                </span>
                <span className="ms-2 fs-12">
                  {selectedsymbolValue && selectedsymbolValue.tradingsymbol}
                </span>
              </div>
              <div className="ms-auto d-flex justify-content-between align-items-center">
                <span className="text-primary me-2">BUY</span>
                <div className="form-check form-switch mx-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onClick={() => {
                      setOrder(!order);
                    }}
                  />
                </div>
                <span className="text-danger">SELL</span>
              </div>
            </div>
            <div className="modal-body">
              {tradestart === 3 && (
                <>
                  <div className="row my-3 justify-content-between align-items-center">
                    <div className="col-md-3 mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="MIS"
                          checked={selectedOption === "MIS"}
                          onChange={handleOptionChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          MIS
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="NRML"
                          checked={selectedOption === "NRML"}
                          onChange={handleOptionChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          NRML
                        </label>
                      </div>
                    </div>

                    <div className="col-md-3 mb-3">
                      <Select
                        options={searchResults}
                        classNamePrefix="select"
                        value={searchTerm}
                        search={true}
                        onChange={handleInputChange}
                        onInputChange={handleSelectChange}
                      />
                    </div>

                    <div
                      className="btn-group  rounded-pill col-md-5"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        value="MARKET"
                        checked={selectedValue === "MARKET"}
                        onChange={handleRadioChange}
                      />
                      <label
                        className="btn btn-sm btn-outline-primary"
                        htmlFor="btnradio1"
                      >
                        MARKET
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        value="LIMIT"
                        checked={selectedValue === "LIMIT"}
                        onChange={handleRadioChange}
                      />
                      <label
                        className="btn btn-sm btn-outline-primary"
                        htmlFor="btnradio2"
                      >
                        LIMIT
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autoComplete="off"
                        value="STOPLIMIT"
                        checked={selectedValue === "STOPLIMIT"}
                        onChange={handleRadioChange}
                      />
                      <label
                        className="btn btn-sm btn-outline-primary"
                        htmlFor="btnradio3"
                      >
                        STOPLIMIT
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        autoComplete="off"
                        value="STOPMARKET"
                        checked={selectedValue === "STOPMARKET"}
                        onChange={handleRadioChange}
                      />
                      <label
                        className="btn btn-sm btn-outline-primary"
                        htmlFor="btnradio4"
                      >
                        STOPMARKET
                      </label>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-2">
                      <label className="fs-10 form-label text-muted fw-semibold">
                        QTY
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        value={formdata.qty}
                        onChange={(e) =>
                          setFormdata({ ...formdata, qty: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-2">
                      <label className="fs-10 form-label text-muted fw-semibold">
                        PRICE
                      </label>
                      <input
                        className="form-control form-control-sm"
                        type="number"
                        value={formdata.price}
                        onChange={(e) =>
                          setFormdata({
                            ...formdata,
                            price: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-4 col-4 mb-2 align-self-end">
                      <div className="d-flex my-1 justify-content-between">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="dayloc"
                            id="inlineRadio1DAY"
                            value="DAY"
                            checked={daylocselect === "DAY"}
                            onChange={handledaylocselect}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1DAY"
                          >
                            DAY
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="dayloc"
                            id="inlineRadio2LOC"
                            value="LOC"
                            checked={daylocselect === "LOC"}
                            onChange={handledaylocselect}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2LOC"
                          >
                            LOC
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="d-flex justify-content-end col-md-6">
                  <button
                    className={`btn btn-sm px-4 btn-${
                      !order ? "primary" : "danger"
                    } me-1`}
                    onClick={() => Finaldata()}
                  >
                    {!order ? "BUY" : "SELL"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary px-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CopyTrade;
